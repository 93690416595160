import { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { updateInstagramFeed } from 'Fire/firebaseActions'
import { findHashtag } from 'Helpers/services'
import announceToScreenReader from 'Helpers/announceToScreenReader'
import { useSlice } from 'State'
import useTimers from 'Hooks/useTimers'
import useIsLoaded from 'Hooks/useIsLoaded'
import useHotkey from 'Hooks/useHotkey'
import HelpTooltip from 'Components/HelpTooltip'
import ControlledInput from 'Components/ControlledInput'
import XIcon from 'Images/icons/x.svg'
import styles from './HashtagSearch.module.scss'

/*
 *   Normalize
 */
function normalize(string) {
  return string.toLowerCase().replace('#', '').replace(/\s/g, '')
}

export default function HashtagSearch({
  instagramUserId,
  parentSourceId,
  feedId,
  hashtags = null,
  onUpdate = null,
}) {
  const { raf } = useTimers()
  const { authorizedAccounts, feeds, plans, user, addNotification } = useSlice(
    'user',
    'plans',
    'feeds',
    'authorizedAccounts',
    'addNotification',
  )
  const { maxHashtagsPerFeed } = plans[user.plan]
  const feed = feeds[feedId] || {}
  const parentAccount = useMemo(
    () => authorizedAccounts[parentSourceId],
    [authorizedAccounts, parentSourceId],
  )

  let { recentlyQueriedHashtags } = Object.values(
    parentAccount.proInstagramAccounts,
  ).filter((account) => account.instagramUserId === instagramUserId)[0]
  recentlyQueriedHashtags = recentlyQueriedHashtags || []
  const containerRef = useRef(null)
  const inputRef = useRef(null)
  const [results, setResults] = useState([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [clearInput, setClearInput] = useState(null)
  const currentHashtags = useMemo(
    () => hashtags || feed?.hashtags || [],
    [feed.hashtags, hashtags],
  )
  const resultsRef = useRef()
  const recentSearchRefs = useRef([])

  const addHashtagToFeed = useCallback(
    (hashtag) => {
      if (maxHashtagsPerFeed < currentHashtags.length + 1) {
        addNotification(
          {
            duration: 20,
            title: 'Feed hashtag limit reached',
            text: (
              <>
                To add more, <Link to="/choose-plan">upgrade your plan</Link>
              </>
            ),
          },
          'MAXPOSTS_UPDATED',
        )
        return false
      }
      if (currentHashtags.map((ht) => ht.id).includes(hashtag.id)) return
      if (onUpdate) {
        onUpdate([...currentHashtags, hashtag])
      } else {
        updateInstagramFeed(feedId, {
          hashtags: [...currentHashtags, hashtag],
        })
      }
      setShowSuggestions(false)
      inputRef.current.focus()
      announceToScreenReader(`Added hashtag ${hashtag.name} to feed`)
    },
    [addNotification, currentHashtags, feedId, maxHashtagsPerFeed, onUpdate],
  )

  const removeHashtagFromFeed = useCallback(
    (hashtagId) => {
      if (onUpdate) {
        onUpdate(currentHashtags.filter((ht) => ht.id !== hashtagId))
      } else {
        updateInstagramFeed(feedId, {
          hashtags: currentHashtags.filter((ht) => ht.id !== hashtagId),
        })
      }
    },
    [currentHashtags, feedId, onUpdate],
  )

  const searchForHashtag = useCallback(
    async (val) => {
      if (!val || val.trim() === '') return
      try {
        setErrorMessage(null)
        setIsLoading(true)
        announceToScreenReader('Searching')

        if (recentlyQueriedHashtags.length > 29) {
          throw new Error("You've hit your weekly search limit")
        }

        const res = await findHashtag(
          parentAccount.id,
          instagramUserId,
          normalize(val),
        )

        setResults([{ name: normalize(val), id: res.id }])
        setShowSuggestions(true)
        setIsLoading(false)
        raf(() => {
          resultsRef.current?.focus()
        })
      } catch (error) {
        if (error.message === 'Internal') {
          error.message = 'Unknown error, please try again'
        }

        inputRef.current.focus()
        setShowSuggestions(true)
        setIsLoading(false)
        setErrorMessage(error.message)
        announceToScreenReader(error.message)
      }
    },
    [instagramUserId, parentAccount],
  )

  const handleDocClick = useCallback((evt) => {
    if (containerRef?.current?.contains(evt.target)) return
    setShowSuggestions(false)
  }, [])

  const focusNextResult = useCallback(() => {
    const resultEls = resultsRef.current ? [resultsRef.current] : []
    const recentEls = [...resultEls, ...recentSearchRefs.current]

    if (recentEls.length && recentEls.includes(document.activeElement)) {
      const currIndex = recentEls.indexOf(document.activeElement)
      if (recentEls[currIndex + 1]) {
        recentEls[currIndex + 1].focus()
      } else {
        recentEls[0].focus()
      }
    } else if (recentEls.length) {
      recentEls[0].focus()
    }
  }, [recentlyQueriedHashtags])

  const focusPrevResult = useCallback(() => {
    const resultEls = resultsRef.current ? [resultsRef.current] : []
    const recentEls = [...resultEls, ...recentSearchRefs.current]

    if (recentEls.length && recentEls.includes(document.activeElement)) {
      const currIndex = recentEls.indexOf(document.activeElement)
      if (currIndex > 0) {
        recentEls[currIndex - 1].focus()
      } else {
        recentEls[recentEls.length - 1].focus()
      }
    } else if (recentEls.length) {
      recentEls[recentEls.length - 1].focus()
    }
  }, [recentlyQueriedHashtags])

  useHotkey('Escape', () => setShowSuggestions(false), showSuggestions)
  useHotkey('Tab', () => setShowSuggestions(false), showSuggestions)
  useHotkey('ArrowUp', () => focusPrevResult(), showSuggestions)
  useHotkey('ArrowDown', () => focusNextResult(), showSuggestions)

  useEffect(() => {
    document.addEventListener('click', handleDocClick)
    return () => document.removeEventListener('click', handleDocClick)
  }, [handleDocClick])

  useEffect(() => {
    recentSearchRefs.current = []
  }, [recentlyQueriedHashtags])

  const hashtagEls = currentHashtags
    ? currentHashtags.map(({ id, name }) => (
        <Hashtag
          key={id}
          id={id}
          name={name}
          removeHashtagFromFeed={removeHashtagFromFeed}
        />
      ))
    : null

  const resultEls = results.map((result) => (
    <button
      key={result.id}
      ref={resultsRef}
      aria-label={`Search results: ${result.name}`}
      className={classnames(styles.result, styles.result_large)}
      onClick={() => {
        addHashtagToFeed(result)
        setClearInput(Date.now())
        setResults([])
      }}
      tabIndex={-1}
    >
      {result.name}
    </button>
  ))

  const previousSearchEls = recentlyQueriedHashtags
    ? recentlyQueriedHashtags
        .filter((ht) => ht.id !== results?.[0]?.id)
        .sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
        .map((query, i) => (
          <button
            aria-label={`${query.name}`}
            key={query.id}
            className={styles.result}
            onClick={() => {
              addHashtagToFeed(query)
            }}
            tabIndex={-1}
            ref={(ref) => (recentSearchRefs.current[i] = ref)}
          >
            {query.name}
          </button>
        ))
    : []

  const shouldShowSuggestions =
    (showSuggestions &&
      (!!errorMessage || !!resultEls.length || !!previousSearchEls.length)) ||
    isLoading

  return (
    <div className={styles.container}>
      {!!hashtagEls?.length && (
        <div className={styles.hashtags}>{hashtagEls}</div>
      )}
      <div ref={containerRef} className={styles.search_interface}>
        <ControlledInput
          ref={inputRef}
          value=""
          updateOn={'button'}
          onChange={searchForHashtag}
          onFocus={() => setShowSuggestions(true)}
          saveButtonText="#&nbsp;Search"
          forceClear={clearInput}
          placeholder="#ExampleHashtag"
          size="large"
          aria-label="Search for hashtags"
        />
        {shouldShowSuggestions && (
          <div className={styles.suggestions}>
            {isLoading && (
              <div className={styles.loading}>
                <l-zoomies
                  size={500}
                  stroke={3.5}
                  color="var(--color-text-green)"
                  speed={1.4}
                  style={{ maxWidth: '100%' }}
                ></l-zoomies>
              </div>
            )}
            {(!!resultEls.length || errorMessage) && !isLoading && (
              <div className={styles.results}>
                <div className={styles.results__label}>Search results</div>
                {errorMessage && (
                  <div className={styles.error}>
                    {errorMessage.includes('search limit') ? (
                      <HelpTooltip
                        width={285}
                        triggerText={
                          <>You&apos;ve hit your weekly search limit</>
                        }
                      >
                        <div className={styles.tooltip}>
                          <h1>Hashtag search limit</h1>
                          <p>
                            Instagram allows a maximum of 30 hashtag lookups per
                            account on a rolling 7 day period. You can still use
                            any recently searched hashtags.
                          </p>
                        </div>
                      </HelpTooltip>
                    ) : (
                      errorMessage
                    )}
                  </div>
                )}
                {resultEls}
              </div>
            )}
            {!!previousSearchEls.length && !isLoading && (
              <div className={styles.results}>
                <div className={styles.results__label}>Recent searches</div>
                {previousSearchEls}
              </div>
            )}
          </div>
        )}
        <div
          className={classnames(styles.query_counter, {
            [styles.at_limit]: recentlyQueriedHashtags.length > 29,
          })}
        >
          <HelpTooltip
            width={295}
            triggerText={
              <>{recentlyQueriedHashtags.length || 0} / 30 Searches</>
            }
          >
            <div className={styles.tooltip}>
              <h1>Hashtag search limit</h1>
              <p>
                Instagram allows a maximum of 30 hashtag lookups per account
                during a rolling 7 day period. You can still use any recently
                searched hashtags.
              </p>
            </div>
          </HelpTooltip>
        </div>
      </div>
    </div>
  )
}

HashtagSearch.propTypes = {
  instagramUserId: PropTypes.string,
  parentSourceId: PropTypes.string,
  feedId: PropTypes.string,
  hashtags: PropTypes.array,
  onUpdate: PropTypes.func,
}

function Hashtag({ id, name, removeHashtagFromFeed }) {
  const isLoaded = useIsLoaded()

  return (
    <div
      key={id}
      className={classnames(styles.hashtag, { [styles.visible]: isLoaded })}
    >
      {name}
      <button
        aria-label={`remove hashtag ${name}`}
        className={styles.hashtag__remove}
        onClick={() => removeHashtagFromFeed(id)}
      >
        <XIcon />
      </button>
    </div>
  )
}

Hashtag.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  removeHashtagFromFeed: PropTypes.func,
}
